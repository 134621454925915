<template>
  <div class="article">
    <div class="articleBox">
      <div class="timebx">发布时间：{{ publishTime }}</div>

      <hr class="hr" />
      <div class="innerbx" v-if="textContent" v-html="textContent"></div>
      <hr class="hr" v-if="textContent" />
      <div class="nodatabx" v-else>
        <img :src="nodataImg" class="img" />
        <p>资料编辑中...</p>
      </div>
      <div class="affixbx" v-if="detailAffix.length > 0">
        <div class="headline">附件</div>
        <div class="affix">
          <table>
            <thead>
              <tr>
                <th style="text-align: left">文件名</th>
                <!-- <th >操作</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in detailAffix" :key="index">
                <td style="width: 90%" :title="item.ItemName">
                  {{ item.ItemName }}
                </td>
                <!-- <td style="text-align: center; white-space: nowrap; "> -->
                <!-- <div v-if="item.FileSize > 1048576">
                    文件超过1M，不支持预览下载 {{parseFloat(item.FileSize/1048576).toFixed(2) }}M
                  </div> -->
                <!-- <div> -->
                <!-- <a :href="apiUrl +'/Item/PreviewFile?fileName=' + encodeURIComponent(item.Source) " target="_blank">
                    <i class="iconfont yt-yulan"></i>预览</a> -->
                <!-- <a :href="apiUrl+'/YSPT/Default/ResponseDownLoad?fileName=' +
                        encodeURIComponent(item.Source)">
                      <i class="iconfont yt-xiazai"></i>下载  
                      {{formatbytes(item.FileSize) }} 
                    </a> -->
                <!-- <button @click="filePreview(item)">预览</button> -->
                <!-- <el-progress v-if="index == progressID" type="line" :percentage="percentage"></el-progress> -->
                <!-- </div> -->
                <!-- </td> -->
              </tr>
            </tbody>
          </table>
<!-- 
          <iframe
            style="height: 100%; overflow: auto; width: 100%"
            frameborder="0"
            :src="fileSrc"
          ></iframe> -->
        </div>
      </div>
      <div class="gopage flexLrTc">
        <router-link
          :to="{
            path: path,
            query: {
              itemId: prevPage.ItemID,
              rootId: prevPage.RootID,
              supItemId: prevPage.ParentID,
              typeCode: prevPage.TypeCode,
            },
          }"
          >{{ prevPage.ItemName ? "上一篇：" : ""
          }}<span>{{ prevPage.ItemName }}</span></router-link
        >
        <router-link
          :to="{
            path: path,
            query: {
              itemId: nextPage.ItemID,
              rootId: nextPage.RootID,
              supItemId: nextPage.ParentID,
              typeCode: nextPage.TypeCode,
            },
          }"
          >{{ nextPage.ItemName ? "下一篇：" : ""
          }}<span>{{ nextPage.ItemName }}</span></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "articleDetail",
  data() {
    return {
      nodataImg: require("../assets/images/nodata.svg"),
      detailAffix: [],
      detailList: [],
      detailLength: 0,
      percentage: 0,
      progressShow: false,
      publishTime: "",
      textContent: "",
      prevPage: {},
      nextPage: {},
      path: "",
    };
  },
  props: {
    detailsCentents: "",
  },
  components: {},
  created() {},
  watch: {
    //    childMethods(a){
    //         alert(a)
    //     },
    // 	detailsFun(){
    //         let that=this;
    //         that.detailArr=that.detailsCentents
    //         that.detailList=that.detailArr.Attr
    //         console.log(that.detailsCentents)
    //     }
  },
  methods: {
    childMethods(arrs, path) {
      let that = this;
      that.path;
      that.detailAffix = arrs.Data.Attach;
      that.detailList = arrs.Data.Attr;
      that.textContent = arrs.Data.Content;

      var tmpPublishTime = null;
      for (var i = 0; i < that.detailList.length; i++) {
        var tmpItem = that.detailList[i];
        if (tmpItem.Source == "CrDate") {
          that.publishTime = tmpItem.Value;
        }
        if (tmpItem.Name == "发布时间") {
          tmpPublishTime = tmpItem.Value;
        }
      }
      if (tmpPublishTime) {
        that.publishTime = tmpPublishTime;
      }

      var obj = {
        rootId: arrs.Data.RootId,
        itemId: arrs.Data.ItemId,
        typeCode: arrs.Data.TypeCode,
        supItemIdNum: arrs.Data.ParentId,
        userId: this.userToken,
      };

      var centent = JSON.stringify(obj);

      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/YSPTApi/PrevNextPage",
        data: centent,
        success: function (res) {
          var tmpprevPage = {};
          var tmpnextPage = {};
          for (var item in res.Data) {
            var model = res.Data[item];
            if (model.Page == "Prev") {
              tmpprevPage = model;
            }
            if (model.Page == "Next") {
              tmpnextPage = model;
            }
          }
          that.prevPage = tmpprevPage;
          that.nextPage = tmpnextPage;
        },
      });
    },
  },
};
</script>
<style scoped>
.articleBox {
  margin: -31px -20px;
}

.articleBox .timebx {
  font-size: 14px;
  color: #999;
  padding: 0 20px;
  margin-bottom: 20px;
}

.articleBox .innerbx {
  line-height: 2.2;
  padding: 20px;
}

.hr {
  height: 4px;
  width: 100%;
  background-color: #f4f7fb;
  border: none;
  margin: 0;
}

.gopage {
  padding: 20px;
}

.gopage a {
  display: flex;
  align-items: center;
  max-width: 40%;
  white-space: nowrap;
}

.gopage a span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.nodatabx {
  text-align: center;
}

.nodatabx img {
  margin: 20px auto;
  height: 20vh;
}

.nodatabx p {
  font-size: 16px;
}
.headline {
  font-size: 14px;
  font-weight: 650;
  text-align: left;
  color: #000000;
  border-bottom: 2px solid #f4f7fb;
  padding: 10px 20px;
}

.affix ul {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}

.affix li {
  flex-grow: 1;
  text-align: left;
  color: #409eff;
  border-top: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
}

.affix thead th,
.affix tbody td {
  padding: 10px 20px;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}

.affix thead th:last-child,
.affix tbody td:last-child {
  border-right: none;
}

.affix tbody td {
  padding: 10px 20px;
}

.affix tbody td a {
  padding: 2px 15px;
  cursor: pointer;
}

.affix tbody td a:hover {
  background: #409eff;
  color: #fff;
}

.affix li a {
  display: block;
  padding: 8px 15px;
}

.affix a:hover {
  background: #409eff;
  color: #fff;
}
</style>
