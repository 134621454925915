<template>
  <div class="page">
    <head-nav />
    <div
      class="inner-ban"
      :style="{ backgroundImage: 'url(' + banner + ')' }"
    ></div>
    <div class="main">
      <div class="auto wrap flexLr">
        <Lefter :bg="menuBackground" @click="fosterFun" />
        <!-- <div class="main-l">
          <div
            class="menu-titbox"
            :style="{ backgroundImage: 'url(' + menuBackground + ')' }"
          >
            人才培养
          </div>
          <ul class="menu-list">
            <li
              :class="fosterIndex == item.typeCodeNum ? 'curBg' : ''"
              v-for="(item, index) in fosterLists"
              :key="index"
              @click="fosterFun(item)"
            >
              {{ item.names }}
            </li>
          </ul>
        </div> -->
        <div class="main-r flex-1 bg-white">
          <div class="conbx" v-show="!dialogTableVisible">
            <div class="inner-tt">
              <span class="bt">{{ fosterTitle }}</span>
            </div>
            <div class="main-wrap">
              <ul class="rc-list flex flex-w flex-pack-justify">
                <li
                  class="item"
                  v-for="(item, index) in fosterDetailsList"
                  :key="index"
                  @click="fosterDetailsFun(item)"
                >
                  <div class="flex flex-align-center">
                    <div class="imgbox">
                      <div class="zoomimg zoomImg">
                        <img
                          :src="
                            item.Thum == null || item.Thum[0] == undefined
                              ? tolerantImg
                              : apiUrl +
                                '/Item/PreviewFile?fileName=' +
                                encodeURIComponent(item.Thum[0])
                          "
                          class="img"
                        />
                      </div>
                    </div>
                    <div class="info">
                      <h6 class="title ellipsis">{{ item.ItemName }}</h6>
                      <p class="date" v-if="item.SF2">性别：{{ item.SF2 }}</p>
                      <p class="date" v-if="item.SF3">出生：{{ item.SF3 }}</p>
                      <p class="date" v-if="item.SF9">
                        {{ item.SF9
                        }}<span v-if="item.SF8">({{ item.SF8 }})</span>
                      </p>
                      <p class="date" v-if="item.SF7">
                        {{ item.SF7 == "" ? "" : "手机号：" + item.SF7 }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- <div class="talents">
                <div class="scheduleTable">
                  <table>
                    <thead>
                      <tr>
                        <th>序号</th>
                        <th>姓名</th>
                        <th>出生日期</th>
                        <th>单位</th>
                        <th>毕业院校</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in fosterDetailsList"
                        :key="index"
                        @click="fosterDetailsFun(item)"
                      >
                        <td>{{ index+1}}</td>
                        <td>{{ item.ItemName }}</td>
                        <td>{{ item.SF4 }}</td>
                        <td></td>
                        <td>{{ item.SF9 }}{{ item.SF8!=''?'('+item.SF8+')':'' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->

              <div class="pagelist" v-show="fosterDetailsList > 10">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 15, 20, 25]"
                  :page-size="pageSize"
                  :total="dataCount"
                  layout="prev, pager, next"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <div class="popbx" v-if="dialogTableVisible">
            <el-dialog
              :title="detailsCentent.ItemName"
              :visible.sync="dialogTableVisible"
              :modal="false"
              class="new-dialog"
            >
              <div class="CententBox">
                <!-- <div class="imgbox">
                 <articleDetail ref='sonV' />
                </div>
                <div class="rightBox">
                  <p>姓名：{{ detailsCentent.ItemName }}</p>
                  <p>性别：{{ detailsCentent.SF2 }}</p>
                  <p>出生日期：{{ detailsCentent.SF3 }}</p>
                  <p>
                    毕业学院：{{ detailsCentent.SF9 }}{{ detailsCentent.SF8!=''?'('+detailsCentent.SF8+')':'' }}
                  </p>
                  <p>
                    {{
                      detailsCentent.SF7 == ""
                        ? ""
                        : "手机号：" + detailsCentent.SF7
                    }}
                  </p>
                  <p>
                    {{
                      detailsCentent.SF10 == ""
                        ? ""
                        : "毕业：" + detailsCentent.SF10
                    }}
                  </p>
                </div> -->
                <articleDetail ref="sonV" />
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>

    <foot />
  </div>
</template>

<script>
import headNav from "@/components/head.vue";
import foot from "@/components/foot.vue";
import articleDetail from "@/components/article.vue";
import Lefter from "../../components/lefter.vue";
export default {
  name: "",
  data() {
    return {
      banner: require("../../assets/images/img_01.jpg"),
      menuBackground: require("../../assets/images/navBg07.jpg"),
      tolerantImg: require("../../assets/images/boshi.png"),
      fosterIndex: "CX016",
      fosterLists: [
        {
          names: "领军人才",
          rootIdNum: "10000",
          itemIdNum: "27",
          typeCodeNum: "CX016",
        },
        {
          names: "博士后",
          rootIdNum: "10000",
          itemIdNum: "28",
          typeCodeNum: "CX017",
        },
        {
          names: "博士",
          rootIdNum: "10000",
          itemIdNum: "29",
          typeCodeNum: "CX018",
        },
        {
          names: "硕士",
          rootIdNum: "10000",
          itemIdNum: "30",
          typeCodeNum: "CX019",
        },
      ],

      // 标题
      fosterTitle: "",
      rootIdNum: "",
      itemIdNum: "",
      typeCodeNum: "",
      fosterDetailsList: [],
      dialogTableVisible: false,
      detailsCentent: "",
      //默认第一页
      currentPage: 1,
      //数据总条数
      dataCount: 0,
      pageSize: 10,
    };
  },
  components: {
    headNav,
    foot,
    articleDetail,
    Lefter,
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.typeCode) {
        var typeCode = this.$route.query.typeCode;
        for (var index in this.fosterLists) {
          var item = this.fosterLists[index];
          if (item.typeCodeNum == typeCode) {
            this.fosterFun(item);
          }
          if (item.typeCodeNum == typeCode && this.$route.query.supItemId) {
            this.UrlDetailRedirect();
          }
        }
      } else {
        var item = this.fosterLists[0];
        this.fosterFun(item);
      }
    },
  },
  mounted() {
    let that = this;
    // 初始化加载奖励列表
    that.rootIdNum = that.fosterLists[0].rootIdNum;
    that.itemIdNum = that.fosterLists[0].itemIdNum;
    that.typeCodeNum = that.fosterLists[0].typeCodeNum;
    that.send(
      that.rootIdNum,
      that.itemIdNum,
      that.typeCodeNum,
      that.currentPage
    );
    that.fosterTitle = that.fosterLists[0].names;
    that.UrlDetailRedirect();
  },
  methods: {
    fosterFun(item) {
      let that = this;
      that.dialogTableVisible = false;
      that.fosterIndex = item.typeCodeNum;
      that.fosterTitle = item.names;
      that.rootIdNum = item.rootIdNum;
      that.itemIdNum = item.itemIdNum;
      that.typeCodeNum = item.typeCodeNum;

      that.send(that.rootIdNum, that.itemIdNum, that.typeCodeNum);
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    // 加载奖励列表
    send(rootIdNum, itemIdNum, typeCodeNum, currentPage) {
      let that = this;
      if (rootIdNum == "" && itemIdNum == "" && typeCodeNum == "") {
        rootIdNum = that.rootIdNum;
        itemIdNum = that.itemIdNum;
        typeCodeNum = that.typeCodeNum;
      }
      var obj = {
        rootId: rootIdNum,
        itemId: itemIdNum,
        typeCode: typeCodeNum,
        pageIndex: currentPage,
        pageSize: that.pageSize,
        DisableItemContent: true,
      };

      var centent = JSON.stringify(obj);

      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/Source/Item",
        data: centent,
        success: function (res) {
          var datas = res.Data;
          that.fosterDetailsList = datas.Items;
          that.dataCount = res.Count;
        },
      });
    },
    handleSizeChange(val) {
      let that = this;
      that.pageSize = val;
      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      let that = this;
      //console.log(`当前页: ${val}`);
      that.send("", "", "", val);
    },
    // 奖励详情
    fosterDetailsFun(item) {
      let that = this;
      var obj = {
        rootId: item.RootId,
        itemId: item.ItemId,
        typeCode: item.TypeCode,
        supItemIdNum: item.ParentId,
        userId: this.userToken,
      };
      var centent = JSON.stringify(obj);
      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/ItemApi/EditDetail",
        data: centent,
        success: function (res) {
          var detailsarr = res.Data.Attr;
          var itemcentent = {
            Thum: [],
            ItemName: "",
            SF2: "",
            SF3: "",
            SF7: "",
            SF8: "",
            SF9: "",
            SF10: "",
          };
          itemcentent.Thum = res.Data.Attach;
          for (var i = 0; i < detailsarr.length; i++) {
            if (detailsarr[i].Source == "ItemName") {
              itemcentent.ItemName = detailsarr[i].Value;
            }
            if (detailsarr[i].Source == "SF2") {
              itemcentent.SF2 = detailsarr[i].Value;
            }
            if (detailsarr[i].Source == "SF3") {
              itemcentent.SF3 = detailsarr[i].Value;
            }
            if (detailsarr[i].Source == "SF7") {
              itemcentent.SF7 = detailsarr[i].Value;
            }
            if (detailsarr[i].Source == "SF8") {
              itemcentent.SF8 = detailsarr[i].Value;
            }
            if (detailsarr[i].Source == "SF9") {
              itemcentent.SF9 = detailsarr[i].Value;
            }
            if (detailsarr[i].Source == "SF10") {
              itemcentent.SF10 = detailsarr[i].Value;
            }
          }
          that.detailsCentent = itemcentent;
          document.documentElement.scrollTop = document.body.scrollTop = 0;
          that.dialogTableVisible = true;
          that.$nextTick(() => {
            that.$refs.sonV.childMethods(res, "/shehuihuodong");
          });
        },
      });

      // var itemcentent = {
      //   Thum: item.Thum,
      //   ItemName: item.ItemName,
      //   SF2: item.SF2,
      //   SF3: item.SF3,
      //   SF7: item.SF7,
      //   SF8: item.SF8,
      //   SF9: item.SF9,
      //   SF10: item.SF10,
      // };
      // this.detailsCentent = itemcentent;
      // this.dialogTableVisible = true;
    },
    //地址详情页跳转
    UrlDetailRedirect() {
      let that = this;
      let rootIdNum = 10000;
      let itemIdNum = this.$route.query.itemId;
      let typeCodeNum = this.$route.query.typeCode;
      let supItemIdNum = this.$route.query.supItemId;
      let names = this.$route.query.name;
      if (!(itemIdNum > 0)) return;
      for (var index in this.fosterLists) {
        var model = this.fosterLists[index];
        if (model.typeCodeNum == typeCodeNum) {
          this.fosterFun(model);
        }
      }
      var obj = {
        RootId: rootIdNum,
        ItemId: itemIdNum,
        TypeCode: typeCodeNum,
        ParentId: supItemIdNum,
      };
      that.fosterIndex = typeCodeNum;
      if (names != "" && names != undefined) {
        setTimeout(function () {
          that.fosterTitle = names;
          that.send(rootIdNum, itemIdNum, typeCodeNum);
        }, 0);
        return;
      }
      that.fosterDetailsFun(obj);
    },
  },
};
</script>

<style>
.space_between {
  display: flex;
  justify-content: space-between;
}
.detailsBox div {
  margin: 15px 0;
  width: 100%;
}
.detailsBox .datas {
  text-align: right;
}
.rc-list .imgbox div {
  padding: 0;
}
.rc-list li {
  cursor: pointer;
}
.zoomImg .img {
  position: initial !important;
}

.CententBox .imgbox {
  width: 130px;
  height: 130px;
  margin-right: 30px;
}
.CententBox .rightBox {
  width: calc(100% - 160px);
}
.rightBox p {
  margin: 5px 0;
  font-size: 16px;
}
.main-wrap .talents {
  width: 100%;
}
/* @* table表格模板 *@  */

.scheduleTable table {
  width: 100%;
  text-align: center;
}

.scheduleTable table thead:nth-child(1) tr {
  background-color: #edf3fb;
  height: 46px;
}

.scheduleTable table tr {
  height: 35px;
}

.scheduleTable table thead,
.scheduleTable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.scheduleTable table tbody {
  display: block;
  overflow-y: scroll;
}

.scheduleTable table tbody::-webkit-scrollbar {
  display: none;
}

.scheduleTable table tbody tr {
  cursor: pointer;
}
.scheduleTable table tbody tr:hover {
  background: #edf3fb;
}

.scheduleTable table thead th,
.scheduleTable tbody tr td {
  color: #575757;
  font-family: serif;
  border: 1px solid #dddddd;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  font-family: "PingFangSC-Regular", "microsoft yahei";
}
.scheduleTable table thead tr th {
  color: #024fa1;
  padding: 2% 0;
}
.scheduleTable tbody tr td {
  border-top: 0px;
  padding: 2% 0;
}
.scheduleTable table tbody tr td:nth-child(2) {
  color: #333;
}
.scheduleTable table thead tr th:nth-child(1),
.scheduleTable table tbody tr td:nth-child(1) {
  width: 60px;
}
.scheduleTable table thead tr th:nth-child(5),
.scheduleTable table tbody tr td:nth-child(5) {
  width: 40%;
}
.scheduleTable table thead tr {
  font-size: 16px;
}
</style>
