var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article"},[_c('div',{staticClass:"articleBox"},[_c('div',{staticClass:"timebx"},[_vm._v("发布时间："+_vm._s(_vm.publishTime))]),_c('hr',{staticClass:"hr"}),(_vm.textContent)?_c('div',{staticClass:"innerbx",domProps:{"innerHTML":_vm._s(_vm.textContent)}}):_vm._e(),(_vm.textContent)?_c('hr',{staticClass:"hr"}):_c('div',{staticClass:"nodatabx"},[_c('img',{staticClass:"img",attrs:{"src":_vm.nodataImg}}),_c('p',[_vm._v("资料编辑中...")])]),(_vm.detailAffix.length > 0)?_c('div',{staticClass:"affixbx"},[_c('div',{staticClass:"headline"},[_vm._v("附件")]),_c('div',{staticClass:"affix"},[_c('table',[_vm._m(0),_c('tbody',_vm._l((_vm.detailAffix),function(item,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"width":"90%"},attrs:{"title":item.ItemName}},[_vm._v(" "+_vm._s(item.ItemName)+" ")])])}),0)])])]):_vm._e(),_c('div',{staticClass:"gopage flexLrTc"},[_c('router-link',{attrs:{"to":{
            path: _vm.path,
            query: {
              itemId: _vm.prevPage.ItemID,
              rootId: _vm.prevPage.RootID,
              supItemId: _vm.prevPage.ParentID,
              typeCode: _vm.prevPage.TypeCode,
            },
          }}},[_vm._v(_vm._s(_vm.prevPage.ItemName ? "上一篇：" : "")),_c('span',[_vm._v(_vm._s(_vm.prevPage.ItemName))])]),_c('router-link',{attrs:{"to":{
            path: _vm.path,
            query: {
              itemId: _vm.nextPage.ItemID,
              rootId: _vm.nextPage.RootID,
              supItemId: _vm.nextPage.ParentID,
              typeCode: _vm.nextPage.TypeCode,
            },
          }}},[_vm._v(_vm._s(_vm.nextPage.ItemName ? "下一篇：" : "")),_c('span',[_vm._v(_vm._s(_vm.nextPage.ItemName))])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"left"}},[_vm._v("文件名")])])])
}]

export { render, staticRenderFns }